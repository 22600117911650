// @import '~antd/dist/antd.less';
// .ant-modal-content {
//   padding: 0px;
// }

.next-antd-modal-content {
  padding: 0;
}

.content {
  background: var(--foreground-rgb);
  color: var(--app-text-color);
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
  padding: 20px 24px;

  > .innerContent {
    margin: 0 auto;
    max-width: 800px;

    > .title {
      font-weight: 400;
      font-size: 28px;
    }

    > .demoContent {
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 24px;
      row-gap: 24px;
    }

    > .desc {
      margin-top: 40px;
      font-weight: 400;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 900px) {
  .content {
    > .innerContent {
      > .demoContent {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

