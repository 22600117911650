.content {
  .alertContainer {
    background: #333;
    color: #fff;
    padding: 30px 20px;
    text-align: center;
    border-radius: 5px;

    > .title {
      font-weight: bold;
      font-size: 26px;
      color: var(--app-text-color);
      margin-top: 20px;
      margin-bottom: 24px;
    }

    > .desc {
      font-weight: 400;
      font-size: 16px;
      color: #999;
      margin-bottom: 40px;
    }

    > .btn {
      min-width: 200px;
      // width: 100%;
      padding: 0 30px;
      height: 50px;
      // background: var(--app-primary-color);
      background: linear-gradient(90deg, #7d4fff 0%, #bd22e4 100%);
      color: var(--app-text-color);
      border-radius: 12px;
    }
  }
}

