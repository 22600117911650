@fontFamily: CrimsonText-Italic;

.content {
  background: var(--foreground-rgb);
  color: var(--app-text-color);
  margin-top: 50px;
  padding: 30px 0;

  > div:nth-of-type(1) {
    text-align: center;
    // font-weight: bold;
    font-size: 48px;
    color: var(--app-text-color);
    font-family: @fontFamily;
  }

  > .desc {
    margin-top: 20px;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    width: 70%;
    margin: 10px auto;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 768px) {
    > h2 {
      text-align: left;
    }

    > .desc {
      text-align: left;
      width: 100%;
    }
  }

  .container {
    margin-top: 50px;

    > div:nth-child(2n) {
      flex-direction: row-reverse;
      text-align: right;

      &:hover {
        background: linear-gradient(270deg, rgba(26, 26, 43, 0.8) 0%, #9873ff 100%) !important;
      }
    }

    > div:nth-child(2n + 1) {
      &:hover {
        background: linear-gradient(90deg, rgba(26, 26, 43, 0.8) 0%, #9873ff 100%) !important;
      }
    }

    > .topInfoCon {
      cursor: pointer;
      padding: 20px;
      background-color: #3c3942;
      margin: 20px 0;
      border-radius: 10px;
      display: flex;
      position: relative;
      overflow: hidden;

      > .deepCopy {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.4;
        z-index: 9;
        background-size: cover;
        background-repeat: no-repeat;
        display: block;
      }

      > .leftInfo {
        z-index: 10;
        // margin-right: 50px;

        > div:nth-of-type(1) {
          > .img {
            height: 55px;
            border-radius: 25px;
          }
        }

        > div:nth-of-type(2) {
          font-size: 14px;
          margin: 5px 0 10px;
          font-family: Poppins, Poppins;
        }

        > div:nth-of-type(3) {
          font-size: 14px;
        }

        > div:nth-of-type(4) {
          font-size: 14px;
        }

        > .moreContainer {
          display: inline-block;
          font-size: 20px;
          margin-top: 20px;
          padding: 7px 65px;
          border-radius: 5px;
          background: linear-gradient(90deg, #9873ff 0%, #7c4eff 100%);
          cursor: pointer;
        }
      }

      > .spaceDiv {
        width: 80px;
      }

      > .rightImgs {
        z-index: 10;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-content: center;

        > div {
          margin: 0 5px;
          position: relative;

          > .img {
            border-radius: 10px;
            width: 100%;
            aspect-ratio: 135/225;
            object-fit: cover;
          }

          > .btn {
            position: absolute;
            padding: 5px 20px;
            background: linear-gradient(90deg, #9873ff 0%, #7c4eff 100%);
            left: 23px;
            border-radius: 6px;
            bottom: 20px;
            opacity: 0;
            cursor: pointer;
          }

          &:hover {
            > .btn {
              opacity: 1;
            }
          }
        }
      }

      > .moreContainer {
        display: none;
        // display: inline-block;
        text-align: center;
        font-size: 20px;
        margin-top: 20px;
        height: 45px;
        line-height: 45px;
        // padding: 7px 65px;
        border-radius: 5px;
        background: linear-gradient(90deg, #9873ff 0%, #7c4eff 100%);
        cursor: pointer;
      }
    }
  }

  > .bottomDesc {
    width: 100%;
    height: 60px;
    text-align: center;
    background-image: url('/images/hairstyle/home_discover_more_bg.webp');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
}

@media screen and (max-width: 900px) {
  .content {
    .container {
      > div:nth-child(n) {
        flex-direction: row;
        text-align: left;

        &:hover {
          background: linear-gradient(90deg, rgba(26, 26, 43, 0.8) 0%, #9873ff 100%) !important;
        }
      }

      > .topInfoCon {
        display: block;

        // > .deepCopy {
        //   z-index: 0;
        // }

        > .leftInfo {
          margin-bottom: 20px;

          > .moreContainer {
            display: none;
          }
        }

        > .rightImgs {
        }

        > .moreContainer {
          width: 100%;
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: 670px) {
  .content {
    .container {
      > .topInfoCon {
        > .rightImgs {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
  }
}

@media (min-width: 500px) and (max-width: 670px) {
  .content {
    .container {
      > .topInfoCon {
        > .rightImgs {
          grid-template-columns: repeat(3, 1fr);
          column-gap: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .content {
    .container {
      > .topInfoCon {
        > .rightImgs {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .content {
    padding: 0;

    > h2 {
      font-size: 32px;
    }

    > .desc {
      font-size: 16px;
    }
  }
}

