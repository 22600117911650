.mainContainer {
  padding: 20px 24px;
  > .imagesList {
    margin-top: 20px;
    display: block;
    white-space: nowrap; /* 禁止文本换行 */
    overflow-x: auto; /* 只允许横向滚动 */
    overflow-y: hidden; /* 禁止纵向滚动 */
    // grid-template-columns: repeat(4,1fr);
    row-gap: 16px;
    column-gap: 16px;

    > li {
      position: relative;
      display: inline-block;
      width: 100px;
      cursor: pointer;

      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }

      > img {
        width: 100px;
        aspect-ratio: 1;
        border-radius: 5px;
        object-fit: cover;
      }

      > p {
        text-align: center;
        font-size: 12px;
        // white-space: word;

        display: -webkit-box;
        overflow: hidden;
        word-break: break-word;
        white-space: normal;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        line-height: 1.2;
      }

      > .selectIcon {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 16px;
        height: 16px;
      }
    }
  }

  > .shareTitleContainer {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .shareTitle {
      font-weight: 600;
      font-size: 20px;
      color: var(--other-text-color);
    }

    > .shareValue {
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      color: var(--other-text-color);
    }
  }

  .copyContainer {
    margin-top: 20px;
    margin-bottom: 24px;
    border: 1px solid #999;
    border-radius: 5px;
    height: 36px;
    display: flex;
    align-items: center;

    > .url {
      width: 100%;
      padding: 0 10px;
      color: var(--app-text-60-color);

      display: -webkit-box;
      overflow: hidden;
      word-break: break-word;
      white-space: normal;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
    }

    > .copy {
      width: 44px;
      border: 0;
      background: transparent;
      cursor: pointer;
    }
  }

  .container {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    > .shareItemContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      > p {
        margin-top: 5px;
        font-size: 10px;
      }
    }
  }
}

.valueListContainer {
  padding: 5px 0;

  > div {
    min-width: 100px;
    cursor: pointer;
    padding: 2px 5px;
    border-radius: 3px;

    &:hover {
      background: #eee;
    }
  }
}

