.content {
  background: #424241;
  padding: 16px;
  border-radius: 10px;
  text-align: center;
  flex-shrink: 0;

  > .title {
    margin-top: 20px;
    font-weight: 400;
    font-size: 20px;
  }

  > .desc {
    margin-top: 16px;
    font-weight: 400;
    font-size: 16px;
    min-height: 72px;
  }

  > img {
    margin-top: 26px;
    display: inline-block;
    height: 100px;
    object-fit: contain;
    width: 100%;
  }
}

