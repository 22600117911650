.groundContent {
  background: #272429;
  color: var(--app-text-color);
  margin-top: 30px;
  border-radius: 5px;
  overflow: hidden;

  .loadingContent {
    padding: 24px;
    text-align: center;

    > .topContainer {
      display: grid;
      place-items: center;
      margin: 50px 0;
    }
  }
  .content {
    padding: 24px;

    .titleContainer {
      font-weight: bold;
      font-size: 20px;
    }

    .subTitleContainer {
      margin-top: 14px;
      font-weight: 400;
      font-size: 14px;
    }

    .pictureContainer {
      cursor: pointer;
      height: 150px;
      border: 1px solid #3c3c3c;
      border-radius: 15px;
      margin-top: 20px;

      display: flex;
      align-items: center;
      justify-content: center;

      > .demoContainer {
        display: flex;
        align-items: center;
        :nth-child(2) {
          margin-left: 10px;
          margin-right: 5px;
        }
      }

      > .imgContainer {
        width: 100%;
        height: 100%;
        position: relative;
        padding: 15px;

        display: flex;
        align-items: center;
        justify-content: center;

        > .image {
          height: 100%;
          aspect-ratio: 1;
          object-fit: cover;
        }

        > .reload {
          position: absolute;
          bottom: 10px;
          right: 10px;
          width: 25px;
          height: 25px;
          cursor: pointer;
        }
      }
    }

    .descContainer {
      margin-top: 10px;
      span {
        color: var(--app-text-color);
      }
    }

    .btnContainer {
      margin-top: 20px;
      text-align: center;

      > .btnContent {
        cursor: pointer;
        min-width: 300px;
        height: 58px;
        border-radius: 5px;
        background: linear-gradient(90deg, #9873ff 0%, #3948d1 100%);

        display: inline-flex;
        align-items: center;
        justify-content: center;

        > .tryOn {
          margin-right: 10px;
        }

        > .iconContent {
          display: inline-flex;
          align-items: center;
          padding-left: 5px;
          padding-right: 5px;

          height: 32px;
          background: var(--app-text-30-color);
          border-radius: 16px;

          > span {
            color: #fff;
          }
        }
      }
    }
  }

  .resultContent {
    // padding: 24px;
    // background-size: cover;
    // background-position: center;
    // filter: blur(10px);

    display: grid;
    place-items: center;
    position: relative;

    > .resultGroundContent {
      opacity: 0.3;
      z-index: 99;
      position: absolute;
      background-size: cover;
      background-position: center;
      filter: blur(10px);
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    .mainContent {
      z-index: 100;
      padding: 24px;

      > .topContainer {
        margin-top: 40px;
        > .topImg {
          display: inline-block;
          height: 300px;
          // aspect-ratio: 540/715;
          object-fit: contain;
          // background: yellow;
          border-radius: 10px;
        }
      }

      > .bottomContainer {
        margin-top: 30px;
        text-align: center;

        > .btn {
          border-radius: 5px;
          padding: 10px;
          width: 44px;
          aspect-ratio: 1;
          margin-right: 10px;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

